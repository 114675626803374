import * as React from "react";
import styled from "styled-components";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import SubTitle from "../components/sub-title/sub-title";
import Title from "../components/title/title";

const Main = styled.main`
  margin-top: 40px;
`;

const Contact = () => {
  return (
    <Layout>
      <SEO
        title={`Thanks for contacting - Ben Pearey`}
        description={`Thanks for contacting`}
      ></SEO>
      <Main>
        <Title>Submitted</Title>
        <SubTitle>Thanks for getting in contact :)</SubTitle>
      </Main>
    </Layout>
  );
};

export default Contact;
